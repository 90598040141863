import React from "react";
import { Link } from "react-router-dom";
import "./FollowersStyle.css";
import '../../App.css'
import followerData from "../Api/Follower";

const UserFollower = () => {
  return (
    <>
      <h4 className="follower-heading">Who is Following you</h4>

      {followerData.map((follower, index) => {
        return (
          <>
            <div className="followers">
              <div className="followers-content">
                <div className="follower-img">
                  <img src={follower.img} alt={follower.name} />
                </div>
                <div className="followers-details">
                  <h5>{follower.name}</h5>
                  <p>{follower.email}</p>
                </div>
                <div className="follow-btn btn-primaryy">
                  <Link to="/">Follow</Link>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default UserFollower;
