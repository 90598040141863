import React from 'react';
import './Home.css'
import LeftSidebar from '../components/leftSidebar/LeftSidebar';
import Post from '../components/Post/Post';
import RightSidebar from '../components/rightSidebar/RightSidebar';


const Home = () => {
  return (
    <>
    <section className="content-spacing">
      <div className="main-inner-content">
      <LeftSidebar/>
      <Post/>
      <RightSidebar/>
      
      </div>
    </section>


    </>
  )
}

export default Home