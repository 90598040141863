import React from "react";
import "./leftSideStyle.css";
import '../../App.css'
import { AiFillTwitterCircle, AiOutlineSearch } from "react-icons/ai";
import LeftSideCart from "./leftSideCart";
import UserFollower from "./UserFollower";
const LeftSidebar = () => {
  return (
    <>
      <section className="rightsidebar">
        <div className="searchbar">
          <div className="twitter-icon">
            <AiFillTwitterCircle
              style={{ color: "orange", fontSize: "2rem" }}
            />
          </div>

          <div className="search">
            <div className="user-search">
              <input type="text" placeholder="#Explore" />
            </div>
            <div className="search-icon">
              <AiOutlineSearch
                style={{
                  background: "orange",
                  color:'#fff',
                  fontSize: "2rem",
                  padding: "5px 0px 2px 0px",
                  borderRadius: "0.2rem",
                }}
              />
            </div>
          </div>
        </div>

        <LeftSideCart />
        <UserFollower />
      </section>
    </>
  );
};

export default LeftSidebar;
