import React from "react";
import "./PostCardStyle.css";
import '../../App.css'

import PostData from "../Api/PostData";

const PostCard = () => {
  return (
    <>
      
        {PostData.map((postData, index) => {
          return (
            <>
              <div className="post-inner-content">
                <img
                  src={postData.img}
                  alt={postData.name}
                  className="post-img"
                />
                <div className="post-share-icons">
                  <img
                    src={
                      postData.liked ? "images/like.png" : "images/notlike.png"
                    }
                    alt="like"
                  />
                  <img src={"images/comment.png"} alt="comment" />
                  <img src={"images/share.png"} alt="share" />
                </div>
                <p>
                  <b>{postData.name}</b> {postData.desc}
                </p>
              </div>
            </>
          );
        })}
  
    </>
  );
};

export default PostCard;
