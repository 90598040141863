const followerData=[
    {
        img:'images/img1.png',
        name:'Sufyan',
        email:'sufyan@gmail.com'
    },
    {
        img:'images/img2.png',
        name:'Ramzan',
        email:'ramzan@gmail.com'
    },
    {
        img:'images/img3.png',
        name:'Ahmed',
        email:'ahmed@gmail.com'
    },
    {
        img:'images/img4.jpg',
        name:'Bilal',
        email:'bilal@gmail.com'
    }

];

export default followerData;