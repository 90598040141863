import { AiFillFolderOpen,AiFillVideoCamera,AiOutlineHeatMap,AiTwotoneCalendar } from "react-icons/ai";

const IconsData = [
  {
    icon: <AiFillFolderOpen/>,
    title: "Photo"
  },
  {
    icon: <AiFillVideoCamera/>,
    title: "Video"
  },
  {
    icon: <AiOutlineHeatMap/>,
    title: "Location"
  },
  {
    icon: <AiTwotoneCalendar/>,
    title: "Schedule"
  }
];

export default IconsData;