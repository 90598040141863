import React from 'react'
import './PostStyle.css'
import '../../App.css'
import PostBar from './PostBar'
import PostCard from './PostCard'


const Post = () => {
  return (
    <>
    <div className="post-user-conetent">
    <PostBar/>
      <PostCard/>

    </div>
      
    </>
  )
}

export default Post