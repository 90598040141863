import React from "react";
import "./PostBarStyle.css";
import '../../App.css'
import IconsData from "../Api/IconsData";
import { Link } from "react-router-dom";

const PostBar = () => {
  return (
    <>
      <section className="post-data">
        <div className="post-share">
          <div className="user-img">
            <img src={"images/profileImg.jpg"} alt="profileImg" />
          </div>
          <div className="post-content">
            <input type="text" placeholder="What's happening" />
          </div>
        </div>
        <div className="post-icons">
          {IconsData.map((iconData, index) => {
            return (
              <>
                <div className="icons-content">
                  <div className="icon">{iconData.icon}</div>
                  <div className="icon-title">
                    <p>{iconData.title}</p>
                  </div>
                </div>
              </>
            );
          })}
          <div className="btn-primary">
            <Link to='#'>Share</Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default PostBar;
