import React from "react";
import "./RightSideStyle.css";
import '../../App.css'
import { Link } from "react-router-dom";

const RightSidebar = () => {
  return (
    <>
      <section className="user-rightbar">
        <div className="user-icons">
          <div className="user-content-img">
            <img src={"images/home.png"} alt="home" />
          </div>
          <div className="user-content-img">
            <img src={"images/noti.png"} alt="noti" />
          </div>
          <div className="user-content-img">
            <img src={"images/logo.png"} alt="logo" />
          </div>
          <div className="user-content-img">
            <img src={"images/comment.png"} alt="comment" />
          </div>
        </div>
        <div className="trending">
        <h3>Trends For your</h3>

          <div className="trending-content">
            <h4>React Js</h4>
            <p>600K Likes</p>
          </div>
          <div className="trending-content">
            <h4>Node Js</h4>
            <p>800K Likes</p>
          </div>
        </div>
        <div className="share-btn btn-primary">
          <Link to="/" className="btn-primary">
            Share
          </Link>
        </div>
      </section>
    </>
  );
};

export default RightSidebar;
