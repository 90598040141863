const PostData = [
  {
    img: "images/postpic1.jpg",
    name: "Laru",
    desc: "Welcome to the New World!",
    likes: "1700 likes",
    liked: true,
  },
  {
    img: "images/postpic2.jpg",
    name: "Laru",
    desc: "Welcome to the New World!",
    likes: "2100 likes",
    liked: false
  },
  {
    img: "images/postpic3.jpg",
    name: "Laru",
    desc: "Welcome to the New World!",
    likes: "2700 likes",
    liked: true
  },
  {
    img: "images/postpic1.jpg",
    name: "Laru",
    desc: "Welcome to the New World!",
    likes: "1200 likes",
    liked: false
  }
];
export default PostData;
