import React from 'react';
import './Cart.css';
import '../../App.css'


const leftSideCart = () => {
  return (
    <>
        <section className="user-cart">
            <div className="user-images">
                <img src={'images/cover.jpg'} alt="cover" />
                <img src={'images/profileImg.jpg'} alt="profileImg" />
            </div>
            <div className="user-details">
                <h1>UX Designer</h1>
                <p>Best Designer in the World!</p>
            </div>
            <div className="follower-status">
                <div className="followerss">
                    <h5>7687</h5>
                    <p>Follower</p>
                </div>
                <div className="followerss">
                    <h5>3487</h5>
                    <p>Following</p>
                </div> 
            </div>
            <div>
                <h2>My Profile</h2>
                </div>
        </section>
    </>
  )
}

export default leftSideCart